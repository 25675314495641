import React, { useEffect, useState } from "react";
import { Button, IconButton, Stack, useMediaQuery } from "@mui/material";
import LinkButton from "../components/LinkButton";
import { useNavigate } from "react-router";
import { GITHUB, INSTAGRAM, LINKDIN, X } from "../assets/porfolioicons";
import { useRecoilState } from "recoil";
import { aWindowState } from "../core/state";

export default function Header() {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  const [stick, setStick] = useState(false);
  const nav = useNavigate();
  const [windowState, setWindowState] = useRecoilState(aWindowState);

  function handleScroll() {
    setStick(Math.ceil(window.scrollY + 10) > 400);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Stack
      rowGap={desktop ? "12px" : "0px"}
      sx={{
        zIndex: 100,
        p: stick ? "8px" : "20px",
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        position: stick ? "sticky" : "unset",
        background: stick ? "#FFFA" : "transparent",
        backdropFilter: "blur(4px)",
        alignItems: "center",
      }}
    >
      <Stack
        id="header"
        direction={desktop ? "row" : "column"}
        alignItems={"center"}
        justifyContent={"space-around"}
        spacing={"160px"}
        sx={{ height: "auto", maxWidth: "1280px" }}
      >
        {/* Social Media Icons  */}
        {(desktop || !stick) && (
          <Stack
            gap={desktop ? "22px" : "10px"}
            direction={"row"}
            alignItems={"center"}
          >
            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://x.com/i0x46"
            >
              <X />{" "}
            </IconButton>

            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://www.instagram.com/i0x46?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            >
              <INSTAGRAM />{" "}
            </IconButton>
            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://github.com/AB-70"
            >
              <GITHUB />{" "}
            </IconButton>
            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://www.linkedin.com/in/abdul70/"
            >
              <LINKDIN />{" "}
            </IconButton>
          </Stack>
        )}

        {/* Desktop Nav Menu */}
        {desktop && (
          <nav
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              // rowGap: "32px",
              columnGap: desktop ? "15px" : "10px",
              padding: desktop ? "20px 32px" : "10px 16px",
              height: "84px",
              width: desktop ? "auto" : "445px",
            }}
          >
            <LinkButton
              observable="Home"
              label="Home"
              onClick={() => {
                if (!window.document.getElementById("Home")) nav("/");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Home")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="My Work "
              observable="work"
              onClick={() => {
                if (!window.document.getElementById("work")) nav("/#work");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("work")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Skills"
              observable="Skills"
              onClick={() => {
                if (!window.document.getElementById("Skills")) nav("/#Skills");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Skills")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Testimonials "
              observable="Testimonials"
              onClick={() => {
                if (!window.document.getElementById("Testimonials"))
                  nav("/#Testimonials");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Testimonials")
                      ?.offsetTop,
                  });
                }
              }}
            />
          </nav>
        )}

        {/* Desktop CTA  */}
        {desktop && (
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <>
              <Button
                sx={{
                  padding: " 12px 26px",
                  borderRadius: "16px",
                  background: "#EF6820",
                  border: "1px solid var(--Orange-500, #EF6820",
                  fontSize: desktop ? "16px" : "12px",
                  ":hover": {
                    background: "#f9d0b9",
                    color: "Black ",
                  },
                }}
                href="https://contra.com/abdulsattar"
              >
                Hire Me
              </Button>
            </>
          </Stack>
        )}
      </Stack>

      {/* Mobile Nav Menu */}
      {!desktop && (
        <nav
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {" "}
          <Stack
            sx={{
              height: "48px",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {/* #F9FAFB */}
            <nav
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                // rowGap: "32px",
                columnGap: desktop ? "15px" : "5px",
                padding: desktop ? "20px 32px" : "8px",
                width: desktop ? "auto" : "auto",
              }}
            >
              <LinkButton
                observable="Home"
                label="Home"
                onClick={() => {
                  if (!window.document.getElementById("Home")) nav("/");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("Home")?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton
                label="My Work "
                observable="work"
                onClick={() => {
                  if (!window.document.getElementById("work")) nav("/#work");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("work")?.offsetTop,
                    });
                  }
                }}
              />
              <LinkButton
                label="Testimonials "
                observable="Testimonials"
                onClick={() => {
                  if (!window.document.getElementById("Testimonials"))
                    nav("/#Testimonials");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("Testimonials")
                        ?.offsetTop,
                    });
                  }
                }}
              />
              {/* label="Resume" */}

              <LinkButton
                observable="contact"
                label="Contact"
                onClick={() => {
                  if (!window.document.getElementById("contact"))
                    nav("/#contact");
                  else {
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("contact")?.offsetTop,
                    });
                  }
                }}
              />

              <LinkButton
                label="Hire Me"
                href="https://contra.com/abdulsattar"
              />
            </nav>
          </Stack>
        </nav>
      )}
    </Stack>
  );
}
