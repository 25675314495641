import { atom } from "recoil";

const localStorageEffect =
  (key: any) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const aWindowState = atom<"hidden" | "visible">({
  default: "hidden",
  key: "aWindowState",
  effects: [localStorageEffect("aWindowState")],
});

export const aChatId = atom<string>({
  default: undefined,
  key: "aChatId",
  effects: [localStorageEffect("aChatId")],
});

export const aUserInfo = atom<any>({
  default: undefined,
  key: "aUserInfo",
  effects: [localStorageEffect("aUserInfo")],
});

export const aStatus = atom<"away" | "available">({
  default: "away",
  key: "aStatus",
});
