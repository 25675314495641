import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeManager from "./middlewares/ThemeManager";
import PageSplashScreen from "./components/SplashScreen";
import "./index.css";
import LandingPage from "./pages";
import { RecoilRoot } from "recoil";
import Livechat from "./pages/chat";
import { Analytics } from "@vercel/analytics/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    <ThemeManager>
      <PageSplashScreen>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/*" element={<LandingPage />} />
          </Routes>
        </BrowserRouter>
        <Analytics />
        <Livechat />
      </PageSplashScreen>
    </ThemeManager>
  </RecoilRoot>
);
