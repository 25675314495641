import React, { useRef } from "react";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aChatId, aStatus, aUserInfo, aWindowState } from "../core/state";
import {
  Chat,
  CheckCircle,
  CheckCircleOutline,
  Close,
  Minimize,
  Send,
} from "@mui/icons-material";
import { GetAvailability } from "../core/rest";
import { SATTAR_DP } from "../assets/porfolioicons";
import { AnimatePresence, motion } from "framer-motion";
import PFMInput from "../components/PFMInput";
import {
  Subscribe,
  connectRtm,
  rtmAckknowledgeMessage,
  rtmCreateChat,
  rtmGetChat,
  rtmGetChatMessages,
  rtmSendChatMessage,
} from "../core/rtm";
import moment from "moment";
import useSound from "use-sound";

export default function Livechat() {
  const [busy, setBusy] = useState(false);
  const [windowState, setWindowState] = useRecoilState(aWindowState);
  // holds email and name
  const [userInfo, setUserInfo] = useRecoilState(aUserInfo);
  const [chatId, setChatId] = useRecoilState(aChatId);
  const [status, setStatus] = useRecoilState(aStatus);
  const desktop = useMediaQuery("screen and (min-width: 710px)");

  const player = useRef<any>(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [msg, setMsg] = useState(""); // editor
  const [chatInfo, setChatInfo] = useState();

  const [forceRefresh, setForceRefresh] = useState(0);

  const msgs = useRef<any[]>([]);

  const listRef = useRef<any>(null);

  async function loadStatus() {
    try {
      const _status = await GetAvailability();
      setStatus(_status);
    } catch (err: any) {}
  }
  const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  async function startChat() {
    try {
      if (!name || !email || !topic || !emailRegex.test(email)) {
        return;
      }
      // Make sure email is email

      setBusy(true);
      // Connect to the rtm api
      await connectRtm(email);
      // When connected, we start chat.
      const chat = await rtmCreateChat({
        name: name,
        email: email,
        subject: topic,
      });
      setChatId(chat.id);
      setUserInfo({ email: email, name: name });
      setChatInfo(chat);
      // Subscribe to event handlers.
      subscribeEvents();
      // Send a message.
      const _msg = await rtmSendChatMessage(chat.id, {
        type: "text",
        content: topic,
      });
      msgs.current.push(_msg);
      setForceRefresh(msgs.current.length);
      setMsg("");
    } catch (err: any) {}
    setBusy(false);
  }

  function subscribeEvents() {
    Subscribe("onMessage", async (data) => {
      const _msg = data as any;
      // Append msg to the current msgs
      msgs.current.push(_msg);
      setForceRefresh(_msg.time);

      // Send reciept
      await rtmAckknowledgeMessage(_msg.chatId, _msg.id, {
        delivered: true,
        read: window.document.hasFocus(),
      });

      // Play tone
      try {
        player.current?.play();
      } catch (err) {}
    });
    Subscribe("onMessageUpdate", (_msg: any) => {
      // A messaage is updated.
      const msgIndex = msgs.current.findIndex((m) => m.id === _msg.id);
      msgs.current[msgIndex] = _msg;
      setForceRefresh(new Date().getTime());
    });
  }

  async function loadChat() {
    try {
      setBusy(true);
      // Connect to the rtm api
      await connectRtm(userInfo.email);
      const _chatInfo = await rtmGetChat(chatId);
      setChatInfo(_chatInfo);
      const _msgs = await rtmGetChatMessages(chatId);
      for (let _m of _msgs) {
        msgs.current.push(_m);
      }
      setForceRefresh(new Date().getTime());
      subscribeEvents();
    } catch (err: any) {}
    setBusy(false);
  }

  async function sendMessage() {
    try {
      if (!msg) {
        return;
      }
      setBusy(true);
      const _msg = await rtmSendChatMessage(chatId, {
        type: "text",
        content: msg,
      });
      msgs.current.push(_msg);
      setForceRefresh(msgs.current.length);
      setMsg("");
    } catch (err: any) {}
    setBusy(false);
  }

  useEffect(() => {
    // Get the availablity status.
    loadStatus();
    if (chatId && userInfo?.email) {
      // Load chat info and previus msgs
      loadChat();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      listRef.current?.scrollTo({
        top: listRef.current.scrollHeight,
        scrollBehavior: "smooth",
      });
    }, 400);
  }, [msgs.current.length, windowState]);

  // Render Messages
  function RenderMessage(m: any) {
    if (!m) return;
    if (m.sender === "user") {
      // Msg is from user
      return (
        <Stack
          spacing={"12px"}
          sx={{
            background: "#222",
            borderRadius: "16px",
            maxWidth: "70%",
            alignSelf: "flex-end",
            px: "12px",
            py: "14px",
            alignItems: "start",
            m: "8px",
          }}
        >
          <Typography sx={{ color: "white" }}>{m.content}</Typography>
          <Stack direction={"row"} alignItems={"flex-end"} sx={{ px: "8px" }}>
            <Typography sx={{ mx: "8px", opacity: 0.5, color: "white" }}>
              {moment.unix(m.time).fromNow()}
            </Typography>
            {m.delivered && !m.read && <CheckCircleOutline color="secondary" />}
            {m.delivered && m.read && <CheckCircle color="secondary" />}
          </Stack>
        </Stack>
      );
    } else {
      if (!m.read) {
        rtmAckknowledgeMessage(m.chatId, m.id, {
          delivered: true,
          read: true,
        }).catch();
      }
      return (
        <Stack direction={"row"} sx={{ px: "8px", my: "8px" }} spacing={"8px"}>
          <img
            src={SATTAR_DP}
            alt="Abdul Sattar"
            style={{
              border: "2.5px solid #F7B27A",
              borderRadius: "50%",
              width: "32px",
              objectFit: "contain",
              height: "32px",
              boxShadow:
                "0px 8px 8px -4px #F7B27A3F, 0px 20px 24px -4px #F7B27A3F",
            }}
          />
          <Stack
            spacing={"12px"}
            sx={{
              background: "#e04f16",
              borderRadius: "16px",
              maxWidth: "70%",
              alignSelf: "flex-start",
              px: "12px",
              py: "14px",
              height: "auto",
              alignItems: "start",
              overflow: "hidden",
            }}
          >
            <Typography
              textAlign={"start"}
              sx={{
                color: "white",
                textWrap: "stable",
                maxWidth: "100%",
                wordWrap: "break-word",
              }}
            >
              {m.content}
            </Typography>
            <Typography sx={{ opacity: 0.5, color: "white" }}>
              {moment.unix(m.time).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    }
  }

  // If window state is hidden, we show button
  if (windowState === "hidden") {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={"16px"}
        sx={{ position: "fixed", bottom: "24px", left: "24px", zIndex: 10 }}
      >
        <audio ref={player} src={"/notification.mp3"} />
        <IconButton
          onClick={() => {
            setWindowState("visible");
          }}
          size="large"
          color="primary"
          sx={{ background: "#e04f162F", height: "64px", width: "64px" }}
        >
          <Chat />
        </IconButton>
        <Typography
          fontSize={desktop ? 22 : 14}
          sx={{ bgcolor: "white", borderRadius: "8px", px: "8px" }}
        >
          {status === "available" ? "Chat with me! 👋" : "Send a message! ✉️"}
        </Typography>
      </Stack>
    );
  } else if (windowState === "visible" && !chatId) {
    // Show new chat window.
    return (
      <Stack
        sx={{
          position: "fixed",
          left: desktop ? "24px" : 0,
          bottom: desktop ? "24px" : 0,
          height: desktop ? "600px" : "100vh",
          width: desktop ? "350px" : "100vw",
          background: "#FFFA",
          backdropFilter: "blur(3px)",
          border: "1px solid #e04f16",
          borderRadius: "16px",
          zIndex: 999,
        }}
      >
        <audio ref={player} src={"/notification.mp3"} />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ px: "18px", py: "12px" }}
        >
          <Typography fontSize={20}>Live Chat </Typography>
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              color="primary"
              onClick={() => setWindowState("hidden")}
            >
              <Minimize />
            </IconButton>
          </Stack>
        </Stack>
        <Stack alignItems={"center"}>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            whileInView={{ scale: 1 }}
            transition={{
              duration: 0.3,
              ease: [0, 0.71, 0.2, 1.01],
              scale: {
                type: "spring",
                damping: 5,
                stiffness: 100,
                restDelta: 0.001,
              },
            }}
          >
            <img
              src={SATTAR_DP}
              alt="Abdul Sattar"
              style={{
                border: "2.5px solid #F7B27A",
                borderRadius: "50%",
                width: "96px",
                objectFit: "contain",
                height: "96px",
                boxShadow:
                  "0px 8px 8px -4px #F7B27A3F, 0px 20px 24px -4px #F7B27A3F",
              }}
            />
          </motion.div>
          <Typography>Abdul Sattar</Typography>
          <Typography>{status === "available" ? "Online" : "Away"}</Typography>
          <Typography
            textAlign={"center"}
            fontSize={16}
            sx={{ px: "18px", my: "8px" }}
          >
            {status === "available"
              ? "Let's have a quick chat! But before we get started, I need you to enter your name and email."
              : "I am currently not available for a live chat, but you can send me a message."}
          </Typography>
        </Stack>
        <Stack sx={{ mx: "18px" }} spacing={"8px"}>
          <PFMInput
            label="Your Name"
            important
            text={name}
            disabled={busy}
            onUpdate={(t) => setName(t)}
          />
          <PFMInput
            label="Email"
            important
            disabled={busy}
            text={email}
            onUpdate={(t) => setEmail(t)}
          />
          <PFMInput
            label="Message"
            important
            text={topic}
            disabled={busy}
            onUpdate={(t) => setTopic(t)}
          />
          <Button
            onClick={startChat}
            disabled={busy}
            fullWidth
            color="primary"
            variant="contained"
          >
            Start
          </Button>
        </Stack>
      </Stack>
    );
  } else if (windowState === "visible" && chatId) {
    return (
      <motion.div
        style={{ zIndex: 99 }}
        initial={{ opacity: 0, zIndex: 99 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Stack
          sx={{
            position: "fixed",
            left: desktop ? "24px" : 0,
            bottom: desktop ? "24px" : 0,
            height: desktop ? "600px" : "100vh",
            width: desktop ? "350px" : "100vw",
            background: "#FFFA",
            backdropFilter: "blur(3px)",
            border: "1px solid #e04f16",
            borderRadius: desktop ? "16px" : 0,
            zIndex: 999,
            overflow: "hidden",
          }}
        >
          <audio ref={player} src={"/notification.mp3"} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ px: "18px", py: "12px" }}
          >
            <Typography fontSize={20}>Live Chat </Typography>
            <Stack direction={"row"} alignItems={"center"}>
              <IconButton
                color="primary"
                onClick={() => setWindowState("hidden")}
              >
                <Minimize />
              </IconButton>
            </Stack>
          </Stack>
          {/* Render msgs  */}
          <Stack flex={1} sx={{ overflow: "auto" }} ref={listRef}>
            <Stack alignItems={"center"}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                whileInView={{ scale: 1 }}
                transition={{
                  duration: 0.3,
                  ease: [0, 0.71, 0.2, 1.01],
                  scale: {
                    type: "spring",
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001,
                  },
                }}
              >
                <img
                  src={SATTAR_DP}
                  alt="Abdul Sattar"
                  style={{
                    border: "2.5px solid #F7B27A",
                    borderRadius: "50%",
                    width: "96px",
                    objectFit: "contain",
                    height: "96px",
                    boxShadow:
                      "0px 8px 8px -4px #F7B27A3F, 0px 20px 24px -4px #F7B27A3F",
                  }}
                />
              </motion.div>
              <Typography>Abdul Sattar</Typography>
            </Stack>
            {msgs.current.map((m) => RenderMessage(m))}
          </Stack>
          <Stack sx={{ p: "12px" }}>
            <PFMInput
              placeholder="Enter a message..."
              text={msg}
              onUpdate={(t) => setMsg(t)}
              onReturn={() => sendMessage()}
              endAdornment={
                <IconButton onClick={sendMessage}>
                  <Send color="primary" sx={{ mx: "8px" }} />
                </IconButton>
              }
            />
            <Typography textAlign={"center"} fontSize={12}>
              Designed & Developed by Abdul Sattar
            </Typography>
          </Stack>
        </Stack>
      </motion.div>
    );
  }

  return <Stack></Stack>;
}
