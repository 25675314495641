import { Badge, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties } from "react";
import { ARROWRIGHT, SHAPE } from "../assets/porfolioicons";
import SHAPE42 from "../assets/porfolioicons/Shape420.png";

const TAG_COLORS = ["#B93815", "#3538CD", "#4488FF", "#F80"];

export default function DevelopmentCard(props: {
  icon?: any;
  heading?: string;
  about?: string;
  onClick?: any;
  style?: CSSProperties;
  tags?: string[];
}) {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      sx={{
        width: desktop ? "384px" : "90%",
        height: desktop ? "470px" : "470px",
        backgroundColor: "#F5F5F4",
        borderRadius: "8px",
        border: "2px solid var(--Gray-200, #EAECF0)",
        padding: desktop ? " 24px" : "10px 24px",
        alignItems: "center",
        lineHeight: "24px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={props.icon}
        style={{
          width: "128px",
          height: "128px",
          objectFit: "contain",
          position: "absolute",
          bottom: "-40px",
          right: "-40px",
          opacity: 0.1,
          filter: "grayscale(100%)",
          transform: "rotate(45deg)",
        }}
        alt=""
      />
      <Stack
        alignItems={"self-start"}
        gap={"52px"}
        sx={{ position: "relative", flex: 1 }}
      >
        <Stack direction={"row"} spacing={"10px"} paddingTop={"24px"}>
          {props.tags?.map((t, i) => (
            <Badge
              sx={{
                background: TAG_COLORS[i] + "1A",
                padding: "4px 12px",
                border: "1px solid " + TAG_COLORS[i] + "3A",
                borderRadius: "16px",
                color: TAG_COLORS[i],
                fontSize: desktop ? "14px" : "12px",
              }}
            >
              {t}
            </Badge>
          ))}
        </Stack>
        <Stack gap={"12px"}>
          <img
            src={props.icon}
            style={{
              width: "64px",
              height: "64px",
              objectFit: "contain",
            }}
            alt=""
          />
          <Typography
            sx={{
              color: "#101828",
              fontSize: desktop ? "36px" : "18px",
              fontStyle: "normal",
              fontWeight: desktop ? "500" : "400",
            }}
          >
            {props.heading}
          </Typography>
          <Typography
            sx={{
              color: "#475467",
              fontSize: desktop ? "18px" : "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "28px",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              alignSelf: "stretch",
            }}
          >
            {props.about}
          </Typography>
        </Stack>

        {props.onClick && (
          <Button
            sx={{
              position: "absolute",
              color: "#FFFFFF",
              background: "#EF6820",
              borderRadius: "16px",
              padding: desktop ? "12px 18px" : "",
              bottom: 0,
              height: "44px",
              fontSize: "18px",
              fontWeight: "500",
              ":hover": {
                background: "#7A5AF8",
                color: "#fff",
              },
            }}
            onClick={props.onClick}
            endIcon={<ARROWRIGHT />}
          >
            View Project
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
