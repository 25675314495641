import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";

export default function DevelopmentProcesCard(props: {
  icon: ReactElement;
  heading: string;
  about: string;
  style?: CSSProperties;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      spacing={"10px"}
      sx={{
        width: desktop ? "384px" : "250px",
        height: desktop ? "230px" : "300px",
        backgroundColor: "none",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.icon}
      </Typography>
      <Typography
        sx={{
          color: "#101828",
          //   fontFamily: " HK Grotesk",
          fontSize: desktop ? "20px" : "18px",
          fontStyle: "normal",
          fontWeight: desktop ? "600" : "400",
          textAlign: "center",
        }}
      >
        {props.heading}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          color: "#475467",
          fontSize: desktop ? "16px" : "16px",
          fontStyle: "normal",
          alignItems: "center",
          fontWeight: "400",
          textAlignLast: "center",
          //   fontFamily: "HK Grotesk",
        }}
      >
        {props.about}
      </Typography>
    </Stack>
  );
}
