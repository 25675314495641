import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import { FLOWER } from "../assets/porfolioicons";

const texts = [
  "Full Stack Development",
  "Web Designing",
  "Web Development",
  "Backend Development",
  "React App Development",
  "ReactNative Development",
  "Telegram Bot Development",
  "Discord Bot Development",
];

export default function SocialProofSection() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Marquee style={{ height: "280px", background: "#F5F5F4" }}>
      {texts.map((t) => (
        <Typography
          sx={{
            fontSize: "36px",
            height: "54px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            fontWeight: "700",
            marginLeft: "20px",
            marginRight: "20px",
            color: "#475467",
          }}
        >
          <FLOWER />
          {t}
        </Typography>
      ))}
    </Marquee>
  );

  return (
    <Stack
      sx={{ overflow: "visible", overflowX: "clip" }}
      justifyContent={"center"}
      alignItems={"center"}
      marginTop={"50px"}
    >
      {desktop && (
        <Stack
          sx={{
            zIndex: 10,
            height: "118px",
            overflow: "hidden",
            background: "#F5F5F4",
            width: "110%",
            // rotate: "4deg",
            flexDirection: "row",
            gap: "50px",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 0 32px 0",
            marginBottom: "100px",
          }}
        >
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: "700",
              color: "#475467",
            }}
          >
            <FLOWER />
            Full Stack Developer{" "}
          </Typography>

          <Typography
            sx={{
              fontSize: "36px",
              height: "54px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontWeight: "700",
              color: "#475467",
            }}
          >
            <FLOWER />
            Web Design
          </Typography>
          <Typography
            sx={{
              fontSize: "36px",
              height: "54px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontWeight: "700",
              color: "#475467",
            }}
          >
            <FLOWER />
            Web Development
          </Typography>

          <Typography
            sx={{
              fontSize: "36px",
              height: "54px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontWeight: "700",
              color: "#475467",
            }}
          >
            <FLOWER />
            Mobile App Development
          </Typography>
          <Typography
            sx={{
              fontSize: "36px",
              height: "54px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontWeight: "700",
              color: "#475467",
            }}
          >
            <FLOWER />
            React App Development
          </Typography>
          <Typography
            sx={{
              fontSize: "36px",
              height: "54px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontWeight: "700",
              color: "#475467",
            }}
          >
            <FLOWER />
            ReactNative Development
          </Typography>
          <Typography
            sx={{
              fontSize: "36px",
              height: "54px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontWeight: "700",
              color: "#475467",
            }}
          >
            <FLOWER />
            Cloud App Development
          </Typography>
        </Stack>
      )}
    </Stack>
    // {desktop && ()}
  );
}
