import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import DualHeading from "../components/DualHeading";
import { motion } from "framer-motion";
import {
  ARROWCONTACT,
  COMPUTER,
  DOTNET,
  GOOGLECLOUD,
  JAVASCRIPT,
  KUBERNETES,
  LEFTCARD,
  MOBILEDEV,
  REACT,
  RIGHTCARD,
  TYPESCRIPT,
  WEBDEV,
} from "../assets/porfolioicons";
import V from "../assets/porfolioicons/V.png";
import bot from "../assets/ICONS/bot.png";

export default function WebSolution() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id="Skills"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        marginTop: desktop ? "100px" : "10px",
        // bgcolor: "#111A",
        py: "48px",
        backgroundImage: `url(${V})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: " top right",
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: desktop ? "" : "50px",
        }}
        spacing={desktop ? "" : "20px"}
      >
        {" "}
        <DualHeading
          sx={{
            fontSize: desktop ? "48px" : "24px",
            textAlign: "center",
            fontWeight: "600",
            color: "#101828",
          }}
          h1="Building Innovative "
          h2=" Web Solutions "
          h2sx={{ color: "#EF6820" }}
        />{" "}
        <Typography
          sx={{
            color: "#475467",
            fontSize: "24px",
            fontWeight: "400",
            textAlign: "center",
            width: desktop ? "100%" : "90%",
          }}
        >
          {" "}
          <motion.div
            initial={{ filter: "blur(5px)" }}
            whileInView={{ filter: "blur(0px)" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            Experienced in Desktop and Web App Development
          </motion.div>
        </Typography>
      </Stack>
      {/* .......................cards */}
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        flexWrap={"wrap"}
      >
        {/*....... LeftSide Cards */}
        <Stack spacing={"24px"} alignItems={"center"} justifyContent={"center"}>
          {/* 1 */}
          <Stack
            gap={"12px"}
            sx={{
              padding: "30px",
              border: "2px solid var(--Gray-200, #EAECF0)",
              backgroundColor: "#F5F5F4",
              width: desktop ? "600px" : "80%",
              height: desktop ? "634px" : "auto",
              gap: desktop ? "40px" : "40px",
              borderRadius: "16px",
            }}
          >
            <Typography>
              <COMPUTER />
            </Typography>
            <Stack spacing={"12px"}>
              <Typography color={"#EF6820"}>Programming</Typography>
              <Typography
                sx={{
                  color: "#101828",
                  fontSize: desktop ? "36px" : "18px",
                  fontStyle: "normal",
                  fontWeight: desktop ? "600" : "400",
                }}
              >
                Skills and Expertise
              </Typography>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: desktop ? "20px" : "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "28px",
                }}
              >
                Proficient in various programming languages, frameworks, and
                tools, including .NET / C#, JavaScript/TypeScript, React,
                ReactNative, and DevOps Solutions such as Google Cloud,
                Kubernetes, and Github Workflows.
              </Typography>
            </Stack>

            {desktop && (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={"24px"}
                marginTop={"24px"}
              >
                <Stack direction={"row"} spacing={"24px"}>
                  <REACT />
                  <JAVASCRIPT />
                  <DOTNET />
                  <KUBERNETES />
                </Stack>
                <Stack direction={"row"} spacing={"24px"}>
                  <TYPESCRIPT />
                  <GOOGLECLOUD />
                </Stack>
              </Stack>
            )}
            {!desktop && (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={"15px"}
              >
                <Stack direction={"row"} spacing={"24px"}>
                  <REACT />
                  <JAVASCRIPT />
                </Stack>
                <Stack direction={"row"} spacing={"24px"}>
                  <DOTNET />
                  <KUBERNETES />
                </Stack>
                <Stack direction={"row"} spacing={"24px"}>
                  <TYPESCRIPT />
                  <GOOGLECLOUD />
                </Stack>
              </Stack>
            )}
          </Stack>
          {/* 2 */}
          <Stack
            direction={desktop ? "row" : "column"}
            spacing={"20px"}
            paddingBottom={desktop ? "auto" : "30px"}
          >
            <Stack
              sx={{
                border: "2px solid var(--Gray-200, #EAECF0)",
                backgroundColor: "#F5F5F4",
                width: "290px",
                height: "386px",
                borderRadius: "16px",
              }}
            >
              <Stack sx={{ padding: "32px 24px 24px 24px" }} gap={"32px"}>
                <Typography>
                  <WEBDEV />
                </Typography>
                <Stack spacing={"12px"}>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: desktop ? "24px" : "18px",
                      fontStyle: "normal",
                      fontWeight: desktop ? "600" : "400",
                    }}
                  >
                    Web Development
                  </Typography>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: desktop ? "16px" : "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "28px",
                    }}
                  >
                    Creating websites and web apps using React, WordPress (with
                    Elementor), and NextJS.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {/* 3 */}
            <Stack
              sx={{
                border: "2px solid var(--Gray-200, #EAECF0)",
                backgroundColor: "#F5F5F4",
                width: "290px",
                height: "386px",
                borderRadius: "16px",
              }}
            >
              <Stack sx={{ padding: "32px 24px 24px 24px" }} gap={"32px"}>
                <Typography>
                  <MOBILEDEV />
                </Typography>
                <Stack spacing={"12px"}>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: desktop ? "24px" : "18px",
                      fontStyle: "normal",
                      fontWeight: desktop ? "600" : "400",
                    }}
                  >
                    Mobile App Development
                  </Typography>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: desktop ? "16px" : "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "28px",
                    }}
                  >
                    Creating user-friendly mobile applications using
                    ReactNative.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* ,,,,,,,,,,........RightSide Cards */}
        <Stack spacing={"24px"} alignItems={"center"} justifyContent={"center"}>
          {/* 1 */}
          <Stack
            sx={{
              padding: desktop ? "30px" : "32px 4px 0px 4px",
              border: "2px solid var(--Gray-200, #EAECF0)",
              borderRadius: "16px",
              backgroundColor: "#F5F5F4",
              width: desktop ? "545px" : "80%",
              height: desktop ? "392px" : "350px",
              gap: desktop ? "40px" : "0",
              position: "relative",
              overflow: "clip",
            }}
          >
            <Stack
              spacing={"12px"}
              paddingLeft={"24px"}
              sx={{ position: "relative" }}
            >
              <Typography color={"#EF6820"}>Innovative Solutions</Typography>
              <Typography
                sx={{
                  color: "#101828",
                  fontSize: desktop ? "36px" : "18px",
                  fontStyle: "normal",
                  fontWeight: desktop ? "600" : "400",
                }}
              >
                Bot Development
              </Typography>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: desktop ? "20px" : "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "28px",
                }}
              >
                Developing chat bots for various platforms, including Telegram,
                Discord, and WhatsApp, fully integrated with third-party APIs
                such as Stripe, NOWPayments, and Cryptochill, as well as
                OpenAI's Assistant API for Support Bots.
              </Typography>
            </Stack>
            <img
              src={bot}
              style={{
                position: "absolute",
                right: -50,
                bottom: -70,
                height: "256px",
                width: "256px",
                filter: "grayscale(1)",
                opacity: 0.5,
              }}
            />
          </Stack>
          {/* 2 */}
          <Stack
            gap={"12px"}
            sx={{
              padding: "32px 24px 0px 4px",
              border: "2px solid var(--Gray-200, #EAECF0)",
              borderRadius: "16px",
              backgroundColor: "#F5F5F4",
              width: desktop ? "545px" : "80%",
              height: desktop ? "628px" : "auto",
              gap: "40px",
            }}
          >
            <Stack spacing={"12px"} paddingLeft={"24px"}>
              <Typography color={"#EF6820"}>Creative Solutions</Typography>
              <Typography
                sx={{
                  color: "#101828",
                  fontSize: desktop ? "36px" : "18px",
                  fontStyle: "normal",
                  fontWeight: desktop ? "600" : "400",
                }}
              >
                Back-end Development
              </Typography>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: desktop ? "20px" : "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "28px",
                }}
              >
                Creating scalable backend solutions for web apps using NodeJS
                and TypeScript, integrated with Firebase Authentication, Redis,
                and MongoDB.
                <br />
                <br />
                Providing both standard RESTful interfaces and real-time APIs
                using Zexcore's RTM API (powered by WebSocket and Redis).
              </Typography>
            </Stack>
            <Typography
              sx={{
                marginTop: "100px",
                display: "flex",
                justifyContent: "center",
              }}
            ></Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        alignItems={"center"}
        style={{ maxWidth: desktop ? "40%" : "90%" }}
      >
        <motion.div
          initial={{ filter: "blur(5px)" }}
          whileInView={{ filter: "blur(0px)" }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
        ></motion.div>
      </Stack>
    </Stack>
  );
}
