import React from "react";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import LinkButton from "../components/LinkButton";
import { useNavigate } from "react-router";
import {
  ABSATTAR,
  GITHUB,
  INSTAGRAM,
  LINKDIN,
  LOGOMARK,
  X,
} from "../assets/porfolioicons";
import footerbg from "../assets/porfolioicons/footerbg.png";

export default function Footer() {
  const desktop = useMediaQuery("(min-width: 720px)");
  const nav = useNavigate();

  return (
    <Stack
      id="footer"
      sx={{
        pt: desktop ? "120px" : "120px",
        pb: "24px",
        backgroundImage: `url(${footerbg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
      spacing="48px"
    >
      {/* The logo, its menu, and subscription container  */}
      <Stack
        direction={desktop ? "row" : "column"}
        justifyContent={desktop ? "center" : "center"}
        sx={{ flexWrap: "wrap" }}
        alignItems={"center"}
        columnGap={"32px"}
        rowGap={"32px"}
      >
        {/* Logo and Menu  */}
        <Stack
          alignItems={desktop ? "center" : "center"}
          spacing={"32px"}
          justifyContent={"center"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={"18px"}
          >
            {" "}
            <LOGOMARK /> <ABSATTAR />
          </Stack>

          <nav
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              // rowGap: "32px",
              columnGap: desktop ? "15px" : "10px",
              padding: desktop ? "20px 32px" : "10px 16px",
              height: "84px",
              width: desktop ? "auto" : "100%",
            }}
          >
            <LinkButton
              observable="Home"
              label="Home"
              onClick={() => {
                if (!window.document.getElementById("Home")) nav("/");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Home")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="My Work "
              observable="work"
              onClick={() => {
                if (!window.document.getElementById("work")) nav("/#work");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("work")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Skills"
              observable="Skills"
              onClick={() => {
                if (!window.document.getElementById("Skills")) nav("/#Skills");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Skills")?.offsetTop,
                  });
                }
              }}
            />
            <LinkButton
              label="Testimonials "
              observable="Testimonials"
              onClick={() => {
                if (!window.document.getElementById("Testimonials"))
                  nav("/#Testimonials");
                else {
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("Testimonials")
                      ?.offsetTop,
                  });
                }
              }}
            />
          </nav>
          <Stack
            gap={desktop ? "22px" : "10px"}
            direction={"row"}
            alignItems={"center"}
          >
            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://x.com/sattar_tech"
            >
              <X />{" "}
            </IconButton>

            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://www.instagram.com/i0x46?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            >
              <INSTAGRAM />{" "}
            </IconButton>
            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://github.com/AB-70"
            >
              <GITHUB />{" "}
            </IconButton>
            <IconButton
              sx={{
                ":hover": {
                  background: "#f2f2f2",
                  color: "white",
                },
              }}
              target="_blank"
              href="https://www.linkedin.com/in/abdul70/"
            >
              <LINKDIN />{" "}
            </IconButton>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-around"}
        flexWrap={"wrap"}
        rowGap={"18px"}
        color={"#667085"}
      >
        <Typography textAlign={"center"}>
          Copyright &copy; 2024. All rights reserved.{" "}
        </Typography>
        {/* <Typography textAlign={"center"}>
          Designed & Developed with 💖 by{" "}
          <Typography
            color={"primary"}
            textAlign={"center"}
            component={"a"}
            sx={{ textDecoration: "none" }}
          >
            OneZeroes, Ltd.
          </Typography>
        </Typography> */}
      </Stack>
    </Stack>
  );
}
