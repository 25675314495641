import moon from "./moon.svg";
import sun from "./sun.svg";
import shape from "./Shape1.svg";
import arrowright from "./arrowright.svg";
import x from "./X.svg";
import linkdin from "./linkdin.svg";
import facebook from "./facebook.svg";
import github from "./github.svg";
import sattar from "./Ellipsesattar.svg";
import arrowdown from "./arrowdown.svg";
import hero from "./hero.svg";
import flower from "./flower.svg";
import divider from "./Divider.svg";
import computer from "./computer.svg";
import react from "./react.svg";
import js from "./js.svg";
import dotnet from "./dotnet.svg";
import kubernetes from "./kubernetes.svg";
import ts from "./ts.svg";
import googlecloud from "./googlecloud.svg";
import leftcard from "./leftcard1.svg";
import moblivedeveolepment from "./MA-development.svg";
import webdev from "./WDservices.svg";
import rightcard from "./rightlastcardicon.svg";
import star from "./Star.svg";
import clender from "./clender.svg";
import design from "./design.svg";
import testing from "./testing.svg";
import abdulsattarlogo from "./AbdulSattar-F-logo.svg";
import logomark from "./Logomark.svg";
import shape420 from "./Shape-420.svg";
import insta from "./instagram.svg";
import arrowcontact from "./arrowrightcontact.svg";
import _sattar_dp from "./abdulsattar.jpg";

export const SATTAR_DP = _sattar_dp;
export const ARROWCONTACT = arrowcontact;
export const INSTAGRAM = insta;
export const SHAPE420 = shape420;
export const ABSATTAR = abdulsattarlogo;
export const LOGOMARK = logomark;
export const SUN = moon;
export const MOON = sun;
export const SHAPE = shape;
export const ARROWRIGHT = arrowright;
export const X = x;
export const LINKDIN = linkdin;
export const FACEBOOK = facebook;
export const GITHUB = github;
export const SATTAR = sattar;
export const ARROWDOWN = arrowdown;
export const HERO = hero;
export const FLOWER = flower;
export const DIVIDER = divider;
export const COMPUTER = computer;
export const REACT = react;
export const JAVASCRIPT = js;
export const DOTNET = dotnet;
export const KUBERNETES = kubernetes;
export const TYPESCRIPT = ts;
export const GOOGLECLOUD = googlecloud;
export const LEFTCARD = leftcard;
export const MOBILEDEV = moblivedeveolepment;
export const WEBDEV = webdev;
export const RIGHTCARD = rightcard;
export const STAR = star;
export const CLENDER = clender;
export const DESGIN = design;
export const TESTING = testing;
