import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import DualHeading from "../components/DualHeading";
import { motion } from "framer-motion";
import DevelopmentCard from "../components/DevolepmentworkCard";
import CASHIFYBOT from "../assets/ICONS/ICONS/Cashifybot.png";
import Announcely from "../assets/ICONS/ICONS/Announcely.png";
import Z from "../assets/ICONS/ICONS/z.png";
import PFM from "../assets/ICONS/ICONS/avatar.png";
import SHAPE from "../assets/ICONS/shape.png";
import Meetup from "../assets/ICONS/ICONS/meetup.png";

export default function DevelopmentSection() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      id="work"
      alignItems={"center"}
      alignSelf={"center"}
      spacing={"32px"}
      justifyContent={"center"}
      sx={{ maxWidth: "1280px", mt: "100px" }}
    >
      <Stack alignItems={"center"}>
        <DualHeading
          sx={{
            fontSize: desktop ? "36px" : "24px",
            fontWeight: "500",
            color: "#101828",
          }}
          h1="My  "
          h2="Development Work"
          h2sx={{ color: "#EF6820" }}
        />
        <Typography
          sx={{
            fontSize: desktop ? "24px" : "14px",
            textAlign: "center",
            fontWeight: "400",
            color: "#475467",
            width: desktop ? "786px" : "90%",
          }}
        >
          <motion.div
            initial={{ filter: "blur(5px)" }}
            whileInView={{ filter: "blur(0px)" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            Diverse Range of Projects From Desktop to Web Applications
          </motion.div>
        </Typography>
      </Stack>

      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        columnGap={"32px"}
        rowGap="32px"
        direction={"row"}
        sx={{
          flexWrap: "wrap",
        }}
      >
        <DevelopmentCard
          icon={CASHIFYBOT}
          heading="Cashifybot"
          about="All-in-one telegram subscriptions solution. Integrated with Stripe and Cryptochill for accepting card payments and crypto, and payouts."
          onClick={() => {
            window.open("https://cashifybot.com", "_blank");
          }}
          tags={["TypeScript", "React", "Bot Development"]}
        />
        <DevelopmentCard
          icon={Announcely}
          heading="Announcely"
          about="Multi-platform bot for forwarding messages from Discord to Telegram and WhatsApp. "
          onClick={() => {
            window.open("https://announcely.app", "_blank");
          }}
          tags={["TypeScript", "React", "Bot Development"]}
        />
        <DevelopmentCard
          icon={PFM}
          heading="Prop Firm Masters"
          about="Website, dashboard, and live chat integration with GPT-4 for Prop Firm Masters (PFM). Fully integrated with Stripe Subscriptions."
          onClick={() => {
            window.open("https://propfirmmasters.com", "_blank");
          }}
          tags={["WordPress", "TypeScript", "React"]}
        />
        <DevelopmentCard
          heading="Zexcore"
          icon={Z}
          about="Developed the analytics and real-time messaging SDK for use in application usage tracking and communication with the backend.  "
          tags={["TypeScript", "Redis", "WebSocket"]}
          onClick={() => {
            window.open("https://core.zexware.com", "_blank");
          }}
        />

        <DevelopmentCard
          heading="MeetUp"
          icon={Meetup}
          about="Developed an online, real-time meeting app (similar to Google Meet) using Agora Video and Zexcore RTM SDK. Click on the button below to try it for free. "
          tags={["TypeScript", "React", "Communication"]}
          onClick={() => {
            window.open("https://meet.zexware.com", "_blank");
          }}
        />

        <DevelopmentCard
          icon={SHAPE}
          tags={["TypeScript", "ReactNative", "Android"]}
          heading="Video Call App"
          about="Powered by Agora.io Video SDK, a video call app that connects random people on Android. Available on Google Play."
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.zexware.strangers",
              "_blank"
            );
          }}
        />

        <DevelopmentCard
          icon={SHAPE}
          tags={["TypeScript", "OpenAI", "Bot Development"]}
          heading="Live Support Bot"
          about="Telegram Live Support bot, powered by OpenAI Assistant API. A customer service bot for online businesses."
          onClick={() => {
            window.open("https://t.me/TgLiveSupportBot", "_blank");
          }}
        />

        <DevelopmentCard
          icon={SHAPE}
          tags={["TypeScript", "Stripe", "Bot Development"]}
          heading="Subscription Bot"
          about="Telegram subscription bot, powered by Stripe API. The bot allows users to subscribe and manage their subscription via Stripe."
          onClick={() => {
            window.open("https://t.me/TgLiveSupportBuyBot", "_blank");
          }}
        />

        <DevelopmentCard
          icon={SHAPE}
          heading="CRYPT-R"
          tags={["C#", "Xamarin", "Cryptography"]}
          about="Cryptography tools for Android. Developed in Xamarin.Android using C# and .NET. "
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.zexware.cryptr",
              "_blank"
            );
          }}
        />

        <DevelopmentCard
          icon={SHAPE}
          heading="ThinkGPT App"
          tags={["TypeScript", "ReactNative", "OpenAI"]}
          about="Built with ReactNative, integrated with OpenAI's Chat Completion API. One of my hobby projects."
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.zexware.thinkgpt",
              "_blank"
            );
          }}
        />

        <DevelopmentCard
          icon={SHAPE}
          heading="AI Voice Assistant Bot"
          tags={["TypeScript", "ElevenLabs AI", "OpenAI"]}
          about="Developed a telegram bot utilizing both ElevenLabs AI and OpenAI Assistant APIs to provide assistance in voice messages. "
        />

        <DevelopmentCard
          icon={SHAPE}
          heading="Livechat"
          tags={["TypeScript", "React"]}
          about="Developed the livechat widget using WebSockets API and Redis, in React and NodeJS. Used on various websites, including this one."
        />

        <DevelopmentCard
          icon={SHAPE}
          heading="XKeyboard"
          tags={["C#", ".NET Framework", "Windows"]}
          about="Provides fancy fonts while writing with a physical keyboard on Windows using low-level keyboard hook from Windows API. "
          onClick={() => {
            window.open("https://github.com/zexware/XKeyboard", "_blank");
          }}
        />

        <DevelopmentCard
          icon={SHAPE}
          heading="Lockex"
          tags={["C#", ".NET Framework", "Windows"]}
          about="File encryption and locking for Windows Desktop. Provides various encryption algorithms to encrypt your important files."
        />
      </Stack>
    </Stack>
  );
}
