import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
import Header from "./header";
import Hero from "./hero";
import Footer from "./footer";
import GoToTop from "./fab";
import DevelopmentSection from "./DevelopmentSection";
import SocialProofSection from "../components/SocialProofSection";
import About from "./about";
import WebSolution from "./webSolution";
import Testmonials from "./Testimonial";
import Demo from "./demo";
import CTASection from "../components/CTAsection";

export default function LandingPage() {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack>
      <Header />
      <Hero />
      {desktop && <SocialProofSection />}
      <DevelopmentSection />
      <About />
      <WebSolution />
      <Testmonials />
      <Demo />
      <CTASection />
      <Footer />
      <GoToTop />
    </Stack>
  );
}
