import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import DualHeading from "../components/DualHeading";
import V from "../assets/porfolioicons/v1.png";
import { motion } from "framer-motion";
import TestimonialCard from "../components/TestimonialsCards";
export default function Testmonials() {
  const [feature, setFeature] = useState("stripe");
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      id="Testimonials"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        backgroundImage: `url(${V})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: " top left",
      }}
    >
      <Stack alignItems={"center"}>
        <DualHeading
          sx={{
            fontSize: desktop ? "36px" : "24px",
            fontWeight: "500",
            color: "#101828",
          }}
          h1="Customer   "
          h2="Feedbacks"
          h2sx={{ color: "#EF6820" }}
        />
        <Typography
          sx={{
            fontSize: desktop ? "24px" : "14px",
            textAlign: "center",
            fontWeight: "400",
            color: "#475467",
            width: desktop ? "786px" : "90%",
          }}
        >
          <motion.div
            initial={{ filter: "blur(5px)" }}
            whileInView={{ filter: "blur(0px)" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            Read what my clients have to say about me!
          </motion.div>
        </Typography>
      </Stack>
      <Stack>
        <Stack
          alignItems={"start"}
          justifyContent={"center"}
          alignSelf={"center"}
          columnGap={"24px"}
          rowGap={desktop ? "24px" : "40px"}
          flexWrap={"wrap"}
          direction={"row"}
        >
          <TestimonialCard
            text="Wanted a Telegram bot with Stripe, OpenAI and Elevenlabs integration. Abdul did a phenomenal job with the project and finished before schedule. No issues at all and really went above and beyond. I will be using him again in the future for other projects. Highly recommend!"
            authorName="Jakobsen"
            authorCompany="Norway"
          />
          <TestimonialCard
            text="Great experience working with AB and Zexware. He's a talented developer, easy to communicate with, and clearly knowledgable in both Node.js and React.js. He works hard, and finished well before his self-imposed deadline. Will come to him first next time we need software development work. Highly recommended."
            authorName="Anio"
            authorCompany="United States"
          />
          <TestimonialCard
            text="AB delivered the project as promised. He had high level understanding of the project deliverables and over-delivered on the scope of the project. We are looking forward to using his services again in the near future!"
            authorName="Internetrnd"
            authorCompany="United States"
          />
        </Stack>
      </Stack>
      <Button
        href="https://www.fiverr.com/zexware"
        sx={{
          borderRadius: "16px",
          pointerEvents: "all",
          fontSize: desktop ? "16px" : "16px",
          border: "1px solid var(--Brand-600, #FAC515);",
          background: "#EF6820",
          padding: desktop ? "12px 18px" : "8px 11px",
          fontWeight: "500",
          ":hover": {
            color: "#344054",
            background: "#FFF",
            border: "1px solid var(--Gray-300, #D0D5DD)",
          },
        }}
      >
        See All Reviews on Fiverr
      </Button>
    </Stack>
  );
}
