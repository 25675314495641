import React from "react";
import { Avatar, Stack, Typography, useMediaQuery } from "@mui/material";
import { STAR } from "../assets/porfolioicons";
export default function TestimonialCard(props: {
  text: string;
  authorName: string;
  authorCompany: string;
}) {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  return (
    <Stack
      className="testimonialCard"
      sx={{
        background: "#F5F5F4",
        padding: "  40px ",
        border: " 2px solid #EAECF0",
        borderRadius: "24px",
        width: desktop ? "384px" : "80%",
      }}
    >
      <Stack className="testimonialCardStyle" sx={{}} spacing={"30px"}>
        <Typography className="flex flex-row">
          <STAR />
          <STAR />
          <STAR />
          <STAR />
          <STAR />
        </Typography>
        <Typography style={{ fontSize: desktop ? "24px" : "18px" }}>
          {props.text}
        </Typography>
        <Stack
          className="testimonialAuthor"
          sx={{ paddingTop: "20px" }}
          spacing={"2px"}
        >
          <Stack direction="row" spacing="8px" alignItems="center">
            <Avatar
              variant="circular"
              sx={{ backgroundColor: "#335", color: "white" }}
            >
              {props.authorName[0]}
            </Avatar>
            <Stack>
              <Typography className="authorName">{props.authorName}</Typography>
              <Typography fontSize={12} className="authorCompany">
                {props.authorCompany}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
