import { AppConfig } from "../config";
import { RtmClient } from "@zexcore/rtm-client";

let client: RtmClient;

export async function connectRtm(email: string) {
  return new Promise(async (resolve, reject) => {
    if (!client) {
      const token = AppConfig.apiKey;
      client = new RtmClient(AppConfig.rtm, {
        reconnectDelayMs: 5000,
        onReconnecting(attempt) {
          console.log(`Trying to reconnect for ${attempt} time...`);
        },
        authenticationData: { token: token, uid: email },
        async onOpen() {
          console.log("Connected to the RTM server.");
          resolve(true);
        },

        onClose() {
          console.log("Connection with the RTM server was closed.");
        },

        onError() {
          console.log("Error connecting to the RTM server");
          reject();
        },
      });
    } else {
      resolve(true);
    }
  });
}

export function Subscribe(
  event: "onMessage" | "onMessageUpdate",
  callback: (data: any[]) => void
) {
  client.Subscribe(event, (data) => {
    callback(data);
  });
}

export function getRtmClient() {
  return client;
}

export async function rtmCreateChat(chat: any) {
  const val = await client.CallWait<any>("rtmCreateChat", chat);
  return val;
}

export async function rtmSendChatMessage(chatId: string, msg: any) {
  const val = await client.CallWait<any>("rtmSendChatMessage", chatId, msg);
  return val;
}

export async function rtmGetChat(chatId: string) {
  const val = await client.CallWait<any>("rtmGetChat", chatId);
  return val;
}

export async function rtmGetChatMessages(chatId: string) {
  const val = await client.CallWait<any[]>("rtmGetChatMessages", chatId);
  return val;
}

export async function rtmAckknowledgeMessage(
  chatId: string,
  messageId: string,
  ack: { read?: boolean; delivered?: boolean }
) {
  const resp = await client.CallWait<boolean>(
    "rtmAckknowledgeMessage",
    chatId,
    messageId,
    ack
  );
  return resp;
}
