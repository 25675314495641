import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import DualHeading from "../components/DualHeading";

import { motion } from "framer-motion";
import SATTARIMAGE from "../assets/porfolioicons/sattarImage.png";
import S from "../assets/porfolioicons/S.png";
import LINE from "../assets/porfolioicons/Line1.png";
import MetricItem from "../components/MetricItem";
import { DIVIDER } from "../assets/porfolioicons";
import containerbg from "../assets/ICONS/about_container.png";

export default function About() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id=""
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        marginTop: desktop ? "100px" : "10px",
        // bgcolor: "#111A",
        py: "48px",
      }}
    >
      {desktop && <DIVIDER />}
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          paddingTop: desktop ? "100px" : "20px",
        }}
        spacing={desktop ? "0" : "20px"}
      >
        <DualHeading
          sx={{
            fontSize: desktop ? "36px" : "24px",
            textAlign: "center",
            fontWeight: "600",
            color: "#101828",
          }}
          h1="Track   "
          h2=" Record of Success"
          h2sx={{ color: "#EF6820" }}
        />{" "}
        <Typography
          fontSize={desktop ? 16 : 14}
          textAlign={"center"}
          sx={{
            color: "#475467",
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "center",
            width: desktop ? "100%" : "90%",
          }}
        >
          {" "}
          <motion.div
            initial={{ filter: "blur(5px)" }}
            whileInView={{ filter: "blur(0px)" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            Presenting Impressive Stats for my Successful Projects and Satisfied
            Clients
          </motion.div>
        </Typography>
      </Stack>
      <Stack
        sx={{
          maxWidth: desktop ? "1280px" : "auto",
          height: "auto",
          alignItems: "center",
          borderSpacing: "32px",
          borderRadius: "16px",
          position: "relative",
        }}
        direction={desktop ? "row" : "column"}
      >
        <img
          src={containerbg}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            right: 0,
            zIndex: -1,
          }}
        />
        <Stack direction={"row"} gap={"10px"}>
          <Stack rowGap={desktop ? "64px" : "20px"}>
            <MetricItem number="75+" heading="Projects completed" />
            <MetricItem number="95%" heading="Satisfied Clients" />
          </Stack>
          <Stack rowGap={desktop ? "64px" : "20px"}>
            <MetricItem number="4+" heading="Years of Experience" />
            <MetricItem number="30+" heading="5-star reviews" />
          </Stack>
        </Stack>
        {desktop && <img src={LINE} alt="" />}
        <Typography>
          <img
            src={SATTARIMAGE}
            alt=""
            style={{
              height: desktop ? undefined : "300px",
              backgroundImage: `url(${S})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />{" "}
        </Typography>
      </Stack>
    </Stack>
  );
}
