import React from "react";
import { CssBaseline, Stack } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    identifier: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    input: true;
  }
}
declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    pfm: true;
  }
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e04f16",
      "600": "#e04f16",
    },
    info: {
      main: "#7A5AF8",
      "600": "#7A5AF8",
    },
    secondary: {
      main: "#7A5AF8",
    },
    background: {
      default: "#FAFAF9",
      // 0C111D
    },
  },
  typography: {
    fontFamily: "Clash Grotesk Variable",
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          ":before": {
            backgroundColor: "#0000",
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        icon: (
          <Stack
            sx={{
              width: "20px",
              height: "20px",
              background: "transparent",
              border: "1px solid #333741",
              borderRadius: "100px",
            }}
          ></Stack>
        ),
        checkedIcon: (
          <Stack
            sx={{
              width: "20px",
              height: "20px",
              background: "white",
              border: "5px solid #4488FF",
              borderRadius: "100px",
            }}
          ></Stack>
        ),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          background: "#FFF1",
          backdropFilter: "blur(5px)",
          overflow: "hidden",
        },
        paper: {
          background: "#0009",
          backdropFilter: "blur(10px)",
          border: "1px solid #FFF3",
          borderRadius: "12px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: {
          p: 0,
        },
      },
      styleOverrides: {
        root: {
          p: 0,
          m: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: "#FFFFFF13",
          border: "1px solid #FFFFFF1F",
          color: "GrayText",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          color: "#FFFFFF",
          ":hover": {
            background: "#f5b18a ",
            color: "#FFFFFF",
          },
        },
        outlinedSecondary: {
          border: "#FFFFFF13 1px solid",
          borderRadius: "50px",
          color: "#CECFD2",
          ":hover": {
            border: "#FFFFFF09 1px solid",
          },
        },
        containedSecondary: {
          background: "#FFFFFF13",
          borderRadius: "50px",
          color: "#CECFD2",
          ":hover": {
            background: "#facfb7 ",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 0,
        },
        head: {
          background: "#4488FF",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: "44px",
          background: "#FFF2",
        },
        root: {
          background: "#FFF1",
          "&:nth-of-type(even)": {
            background: "#FFF0",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#667085",
          "&.required:after": {
            content: "'*'",
            color: "red",
            padding: "4px",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          color: "#CECFD2",
          background: "#FFF1",
        },
        filledWarning: {
          border: "1px solid #F79009",
          borderRadius: "8px",
          background: "#FFF1",
        },
        filledSuccess: {
          border: "1px solid #079455",
          borderRadius: "8px",
        },
        filledInfo: {
          border: "1px solid #4488FF",
          borderRadius: "8px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        clickable: {
          ":hover": {
            background: "#4488FF",
            cursor: "pointer",
            color: "black",
          },
        },
        colorInfo: {
          color: "#448BFFAA",
        },
        colorPrimary: {
          color: "#448BFFAA",
        },
      },
      variants: [
        {
          props: {
            variant: "identifier",
          },
          style: {
            background: "#FFF1",
            borderRadius: "25px",
            cursor: "default",
            transition: "all .2s",
            textTransform: "capitalize",
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          stroke: "#1F242F",
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: {
            color: "input",
          },
          style: {
            width: "100%",
            height: "100%",
            borderRadius: "6px",
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: "#000E",
          backdropFilter: "blur(1px)",
          borderRadius: "6px",
          padding: "0px",
          margin: "0px",
        },
        list: {
          margin: "4px",
          padding: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          marginTop: "4px",
          marginBottom: "4px",
        },
      },
    },
  },
});

export default function ThemeManager(props: any) {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
