import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import DualHeading from "../components/DualHeading";

import { motion } from "framer-motion";
// import {
//   CHATGPTBOT,
//   MIRRORBOTS,
//   MODERATIONBOTS,
//   PAYMENTBOTS,
//   SHOPPING,
// } from "../assets/icons";
import DevelopmentProcesCard from "../components/DevelopmentProcesCard";
import { CLENDER, DESGIN, DIVIDER, TESTING } from "../assets/porfolioicons";
export default function Demo() {
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id="Blog"
      alignItems={"center"}
      spacing={"32px"}
      justifyContent={"center"}
      marginTop={desktop ? "40px" : "0"}
      sx={{
        // bgcolor: "#111A",
        py: "48px",
      }}
    >
      {" "}
      {desktop && <DIVIDER />}
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        width={desktop ? "60%" : "90%"}
        spacing={desktop ? "0" : "20px"}
        paddingTop={desktop ? "40px" : "0"}
      >
        <DualHeading
          sx={{
            fontSize: desktop ? "36px" : "24px",
            textAlign: "center",
            fontWeight: "600",
            color: "#101828",
          }}
          h1="From concept to deployment, my  "
          h2=" seamless development process "
          h2sx={{ color: "#EF6820" }}
        />

        <Typography
          textAlign={"center"}
          sx={{
            color: "#475467",
            fontSize: desktop ? "20px" : "16px",
            fontWeight: "400",
            textAlign: "center",
            width: desktop ? "90%" : "90%",
          }}
        >
          <motion.div
            initial={{ filter: "blur(5px)" }}
            whileInView={{ filter: "blur(0px)" }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            I systematically bring your ideas to life through planning, design,
            development, testing, and deployment. My expertise ensures
            successful project delivery.
          </motion.div>
        </Typography>
      </Stack>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
        direction={"row"}
        rowGap={"24px"}
        columnGap={"24px"}
        flexWrap={"wrap"}
      >
        <DevelopmentProcesCard
          icon={<CLENDER />}
          heading="Conceptualization and Planning"
          about="I follow a streamlined development process to ensure precision and efficiency in executing your project. From concept to deployment, I work closely with my clients to exceed their expectations."
        />

        <DevelopmentProcesCard
          icon={<DESGIN />}
          heading="Design and Development"
          about="With my experienced team of designers and developers, we work together to create user-centered web and app solutions, prioritizing usability, accessibility, and aesthetics for an exceptional user experience."
        />

        <DevelopmentProcesCard
          icon={<TESTING />}
          heading="Testing and Deployment"
          about="To ensure the reliability and functionality of your project, my team conducts rigorous testing to identify and resolve any issues, ensuring a seamless end product."
        />
      </Stack>
    </Stack>
  );
}
