const debug = {
  version: "1.0.0",
  build: "Dec 1, 2023",
  api: "http://localhost:80",
  rtm: "ws://localhost:80",
  apiKey: "1a73a6f3-81e9-4d25-bdaa-43f0587d1db6",
};

const prod = {
  version: "1.0.8",
  build: "May 1, 2024",
  api: "https://livechat.zexware.com",
  rtm: "wss://livechat.zexware.com",
  apiKey: "1a73a6f3-81e9-4d25-bdaa-43f0587d1db6",
};

const DEBUG = process.env.REACT_APP_DEBUG === "true";

export const AppConfig = DEBUG ? debug : prod;
