import { Stack, Typography, useMediaQuery } from "@mui/material";
import React, { CSSProperties } from "react";

export default function MetricItem(props: {
  number?: any;
  heading?: string;
  style?: CSSProperties;
}) {
  const desktop = useMediaQuery("(min-width: 700px)");

  return (
    <Stack
      spacing={"4px"}
      sx={{
        width: desktop ? "280px" : "150px",
        height: desktop ? "100px" : "90px",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: desktop ? "60px" : "36px",
          fontWeight: 600,
          color: "#E04F16",
        }}
      >
        {/* 75+ */}
        {props.number}
      </Typography>
      <Typography
        sx={{
          color: "#101828",
          fontSize: desktop ? "18px" : "16px",
          fontStyle: "normal",
          fontWeight: desktop ? "600" : "500",
          textAlign: "center",
        }}
      >
        {/* Projects completed */}
        {props.heading}
      </Typography>
    </Stack>
  );
}
