import _ic_email from "./mail-01.svg";
import _ic_password from "./circle-password.svg";
import _ic_user from "./user.svg";
import _ic_show from "./view.svg";
import _ic_hide from "./hide.svg";
import _ic_dashboard from "./dashboard-square-01.svg";
import _ic_edit from "./edit-01.svg";
import _ic_edit_email from "./mail-edit-01.svg";
import _ic_credit_card from "./credit-card.svg";
import _ic_credit_card_edit from "./credit-card-change.svg";
import _ic_credit_card_delete from "./credit-card-not-accept.svg";
import _ic_credit_card_add from "./credit-card-add.svg";
import _ic_settings from "./configuration-01.svg";
import _ic_help from "./help-circle.svg";
import _ic_invoice from "./invoice-02.svg";
import _ic_logout from "./logout-01.svg";
import _ic_refresh from "./refresh.svg";
import _ic_search from "./search-02.svg";
import _ic_transactions from "./transaction.svg";
import _ic_users from "./user-multiple.svg";
import _ic_user_settings from "./user-settings-01.svg";
import _ic_user_info from "./user.svg";
import _ic_user_add from "./user-add-02.svg";
import _ic_user_remove from "./user-remove-02.svg";
import _ic_checkmark from "./checkmark-circle-01.svg";
import _ic_wallet from "./wallet-02-1.svg";
import _ic_alert from "./alert-circle.svg";
import _ic_password_validated from "./password-validation.svg";
import _ic_deployment from "./codesandbox.svg";
import _ic_cancel from "./cancel-circle.svg";
import _ic_copy from "./ic_copy.svg";
import _ic_link from "./link-03.svg";

// Lock icons
import _ic_lock from "./circle-lock-02.svg";
import _ic_lock_add from "./circle-lock-add-01.svg";
import _ic_lock_check from "./circle-lock-check-01.svg";
import _ic_lock_remove from "./circle-lock-remove-01.svg";
import _ic_lock_minus from "./circle-lock-minus-01.svg";

// affiliation
import _ic_affiliate from "./affiliate.svg";
import _ic_code from "./code-square.svg";
import _ic_paid from "./payment-success-01.svg";
import _ic_pending from "./loading.svg";
import _ic_balance from "./coins-dollar.svg";

// Export
export const IC_LINK = _ic_link;
export const IC_COPY = _ic_copy;

// affiliation
export const IC_AFFILIATE = _ic_affiliate;
export const IC_CODE = _ic_code;
export const IC_PAID = _ic_paid;
export const IC_PENDING = _ic_pending;
export const IC_AFFILIATE_BALANCE = _ic_balance;

// Lock Icons
export const IC_LOCK = _ic_lock;
export const IC_LOCK_ADD = _ic_lock_add;
export const IC_LOCK_CHECK = _ic_lock_check;
export const IC_LOCK_REMOVE = _ic_lock_remove;
export const IC_LOCK_MINUS = _ic_lock_minus;

export const IC_CANCEL = _ic_cancel;
export const IC_EMAIL = _ic_email;
export const IC_PASSWORD = _ic_password;
export const IC_UESR = _ic_user;
export const IC_SHOW = _ic_show;
export const IC_HIDE = _ic_hide;
export const IC_DASHBOARD = _ic_dashboard;
export const IC_EDIT = _ic_edit;
export const IC_EDIT_EMAIL = _ic_edit_email;
export const IC_CREDIT_CARD = _ic_credit_card;
export const IC_EDIT_CREDIT_CARD = _ic_credit_card_edit;
export const IC_DELETE_CREDIT_CARD = _ic_credit_card_delete;
export const IC_SETTINGS = _ic_settings;
export const IC_HELP = _ic_help;
export const IC_INVOICE = _ic_invoice;
export const IC_LOGOUT = _ic_logout;
export const IC_REFRESH = _ic_refresh;
export const IC_SEARCH = _ic_search;
export const IC_TRANSACTION = _ic_transactions;
export const IC_USERS = _ic_users;
export const IC_USER_SETTINGS = _ic_user_settings;
export const IC_USER_INFO = _ic_user_info;
export const IC_USER_ADD = _ic_user_add;
export const IC_USER_REMOVE = _ic_user_remove;
export const IC_ADD_CARD = _ic_credit_card_add;
export const IC_CHECKMARK = _ic_checkmark;
export const IC_BALANCE = _ic_wallet;
export const IC_PASSWORD_VALIDATED = _ic_password_validated;
export const IC_ALERT = _ic_alert;
export const IC_DEPLOYMENT = _ic_deployment;
