import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import { motion } from "framer-motion";
import LAPTOP from "../assets/porfolioicons/laptop.png";
import containerbg from "../assets/ICONS/about_container.png";
import { useRecoilState } from "recoil";
import { aWindowState } from "../core/state";
export default function CTASection() {
  const [windowState, setWindowState] = useRecoilState(aWindowState);
  const desktop = useMediaQuery("(min-width: 700px)");
  return (
    <Stack
      id="contact"
      alignItems={"center"}
      spacing={"32px"}
      sx={{
        py: "48px",
      }}
    >
      <Stack
        sx={{
          maxWidth: desktop ? "1280px" : "80%",
          height: "auto",
          alignItems: "center",
          borderWidth: "4px",
          overflow: "clip",
          padding: "8px",
          borderRadius: "16px",
          position: "relative",
        }}
        direction={desktop ? "row" : "column"}
      >
        <img
          src={containerbg}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            right: 0,
            zIndex: -1,
          }}
        />
        <Stack>
          <Stack padding={desktop ? "64px" : "0"}>
            <Typography
              sx={{
                color: "#101828",
                fontSize: "36px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Transforming Ideas Into Reality
            </Typography>{" "}
            <Typography
              sx={{
                color: "#475467",
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Contact me today to discuss your project and bring it to life.
            </Typography>
            <motion.div
              whileInView={{ scale: 1 }}
              initial={{ scale: 0 }}
              transition={{ type: "spring", stiffness: 100, delay: 0 }}
            >
              <Stack
                gap={"10px"}
                direction={"row"}
                paddingTop={"48px"}
                alignItems={desktop ? undefined : "center"}
                justifyContent={desktop ? undefined : "center"}
              >
                <Button
                  sx={{
                    borderRadius: "16px",
                    pointerEvents: "all",
                    fontSize: desktop ? "16px" : "16px",
                    border: "1px solid var(--Brand-600, #FAC515);",
                    background: "#EF6820",
                    padding: desktop ? "12px 18px" : "8px 11px",
                    fontWeight: "500",
                    ":hover": {
                      color: "#344054",
                      background: "#FFF",
                      border: "1px solid var(--Gray-300, #D0D5DD)",
                    },
                  }}
                  onClick={() => {
                    setWindowState("visible");
                  }}
                >
                  Send a Message!
                </Button>
              </Stack>
            </motion.div>
          </Stack>
        </Stack>
        <Typography sx={{ paddingTop: desktop ? 0 : "50px" }}>
          <img
            src={LAPTOP}
            alt=""
            style={{ width: desktop ? undefined : "100%" }}
          />{" "}
        </Typography>
      </Stack>
    </Stack>
  );
}
