import React from "react";
import { useState, useEffect } from "react";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { LOGOHEADER, LOGO_SIDEBAR } from "../assets/brand";

export default function PageSplashScreen({ children }: any) {
  const [initialized, setInitialized] = useState(false);
  const desktop = useMediaQuery("(min-width: 700px)");

  useEffect(() => {
    setTimeout(() => setInitialized(true), 1000);
  }, []);

  if (initialized) {
    return <>{children}</>;
  }

  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100%",
        alignItems: "center",
        background: "#ffff",
      }}
    >
      <Typography
        sx={{
          position: "fixed",
          top: desktop ? "64px" : undefined,
          left: desktop ? "80px" : undefined,
          pointerEvents: "none",
          height: "100px",
          objectFit: "contain",
          width: desktop ? undefined : "300px",
        }}
      ></Typography>
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Player
          autoplay
          loop
          src="https://lottie.host/a0818aa6-46ce-463d-bce6-75aeae1ebb34/mAGb0W7sxo.json"
          style={{ height: desktop ? "180px" : "100px" }}
        />
      </Stack>
    </Stack>
  );
}
