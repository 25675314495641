import React, { useEffect, useState } from "react";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import Back from "../assets/porfolioicons/101.png";
import dev from "../assets/porfolioicons/dev.png";
import { motion } from "framer-motion";
import sattar from "../assets/porfolioicons/Ellipse2.png";
import { ARROWDOWN, SATTAR_DP } from "../assets/porfolioicons";
export default function Hero() {
  const [scrollOffset, setScrollOfset] = useState(window.scrollY);
  const desktop = useMediaQuery("(min-width: 700px)");
  const nav = useNavigate();
  function handleScroll() {
    setScrollOfset(Math.ceil(window.scrollY));
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Stack
      id="Home"
      alignItems={"center"}
      justifyContent={desktop ? "center" : "flex-start"}
      sx={{
        height: desktop ? "auto" : undefined,
        overflow: "hidden",
        paddingBottom: desktop ? "120px" : "120px",
        paddingTop: desktop ? "50px" : "50px",
        backgroundImage: `url(${Back})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      spacing={desktop ? "46px" : "24px"}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        whileInView={{ scale: 1 }}
        transition={{
          duration: 0.3,
          ease: [0, 0.71, 0.2, 1.01],
          scale: {
            type: "spring",
            damping: 5,
            stiffness: 100,
            restDelta: 0.001,
          },
        }}
      >
        <img
          src={SATTAR_DP}
          alt="Abdul Sattar"
          style={{
            border: "2.5px solid #F7B27A",
            borderRadius: "50%",
            width: "124px",
            objectFit: "contain",
            height: "124px",
            boxShadow:
              "0px 8px 8px -4px #F7B27A3F, 0px 20px 24px -4px #F7B27A3F",
          }}
        />
      </motion.div>
      <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
        👋🏻 Hi, I'm Abdul Sattar
      </Typography>
      <Stack
        justifyContent={desktop ? "center" : "center"}
        alignItems={"center"}
        spacing={desktop ? "24px" : "20px"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent="center"
          sx={{
            color: "#101828",
            fontSize: desktop ? "48px" : "18px",
            fontWeight: "600",
            textAlign: "center",
            flexWrap: "wrap",
            rowGap: "8px",
            columnGap: "8px",
            maxWidth: desktop ? "70%" : "100%",
          }}
        >
          <Typography
            sx={{
              color: "#101828",
              fontSize: desktop ? "48px" : "18px",
              fontWeight: "500",
              display: "inline",
            }}
          >
            Passionate
          </Typography>

          <span
            style={{
              paddingRight: "8px",
              paddingLeft: "8px",
              background: "#F9DBAF",
              borderRadius: "10px",
              color: "#344054",
              display: "inline",
              fontWeight: "500",
            }}
          >
            Full-Stack{" "}
            <img
              src={dev}
              alt=""
              style={{
                height: desktop ? "48px" : "22px",
                width: desktop ? "48px" : "22px",
              }}
            />{" "}
            Developer
          </span>
          <span
            style={{
              paddingRight: "8px",
              paddingLeft: "8px",
              background: "#F9DBAF",
              borderRadius: "10px",
              color: "#344054",
              fontStyle: "normal",
              fontWeight: "500",
              display: "inline",
            }}
          >
            Creating Innovative
          </span>
          <Typography
            sx={{
              color: "#101828",
              fontSize: desktop ? "48px" : "18px",
              fontWeight: "500",
              fontStyle: "normal",
              display: "inline",
            }}
          >
            Web Solutions
          </Typography>
        </Stack>

        <Typography
          fontSize={desktop ? "20px" : "16px"}
          fontWeight={500}
          style={{
            maxWidth: desktop ? "666px" : "80%",
            color: "#667085",
            fontStyle: "normal",
            fontWeight: "normal",
          }}
          textAlign={"center"}
        >
          4 years of practical experience in desktop, web and mobile app
          development. I bring expertise and creativity to every project.
        </Typography>
      </Stack>
      <motion.div
        whileInView={{ scale: 1 }}
        initial={{ scale: 0 }}
        transition={{ type: "spring", stiffness: 100, delay: 0 }}
      >
        <Button
          endIcon={<ARROWDOWN />}
          variant="contained"
          sx={{
            borderRadius: "16px",
            pointerEvents: "all",
            fontSize: desktop ? "18px" : "16px",
            border: "1px solid var(--Brand-600, #FAC515);",
            background: "#EF6820",
            padding: desktop ? "16px 22px" : "8px 11px",
          }}
          onClick={() => {
            if (!window.document.getElementById("work")) nav("/#work");
            else {
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("work")?.offsetTop,
              });
            }
          }}
        >
          Explore Portflio
        </Button>
      </motion.div>
    </Stack>
  );
}
